export let BaseUrl = `https://GiftedTest.ssey.com.tw/`;
// export let BaseUrl = `http://localhost:8080`;
// http://localhost:8080
// https://GiftedTest.ssey.com.tw/
// https://GiftedTesths.ssey.com.tw/
// https://www.elemgiftedness.tyc.edu.tw/

//1.國小
//2.國中
export let SchoolType = 1;

export let blob_download = function (_res, _filename) {
  if (_res.headers["content-type"] == "application/json; charset=utf-8") {
    return { Success: false, Message: "下載異常" };
  }
  if (_res.data.type == "application/json") {
    var data = "";
    var reader = new FileReader();
    reader.addEventListener("loadend", function (e) {
      data = JSON.parse(e.srcElement.result);
      if (!data.Success) return { Success: false, Message: data.Message };
    });
    reader.readAsText(_res.data);
  } else {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      window.navigator.msSaveOrOpenBlob(new Blob([_res.data]), _filename);
    } else {
      const url = URL.createObjectURL(new Blob([_res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", _filename);
      document.body.appendChild(link);
      link.click();
      if (typeof link.remove != "undefined") link.remove();
    }
  }
  return { Success: true };
};
